import { ImagesContext } from "../../context/ImagesContext";
import { useContext } from "react";



export const getMockProductData  = () => {
    const images = [
        
    ]
    return {
        products: [
            {
                id: 1,
                name: "Plague Marine Shoulders",
                price: 19.99,
                image: '/images/Gemini_Generated_Image_705wo9705wo9705w.jpg',
            },
            {
                id: 2,
                name: "Plague Marine Helmets",
                price: 19.99,
                image: '/images/Gemini_Generated_Image_aloxyealoxyealox.jpg',
            },
            {
                id: 3,
                name: "White Scar Marine Shoulders",
                price: 19.99,
                image: '/images/Gemini_Generated_Image_aloxygaloxygalox.jpg',
            },
            {
                id: 4,
                name: "White Scar Marine Helmets",
                price: 19.99,
                image: '/images/Gemini_Generated_Image_fyeae2fyeae2fyea.jpg',
            }
        ]
    }
};