import React from 'react';
import s from './Product.module.css'; // Import the CSS module

const Product = ({onAddToCart, product }) => {


  return (
    <div className="product-card">
      <img className={s.image} src={product.image} alt={product.name} />
      <h3>{product.name}</h3>
      <p>${product.price}</p>
    </div>
  );
};

export default Product;