import s from "./Error.module.css"
const Error = () => {
   
    return (
      <div className={s.error}>
        <h1>OOPS Something Bad Happened..</h1>
      </div>
    );
  };
  
  export default Error;