export const createAccountValidation = (
  userId,
  email,
  password,
  name,
  phone
) => {
  //check for empty feilds
  if (isEmpty(name)) throw new Error("Please fill in both name feilds");
  if (isEmpty(email)) throw new Error("Please fill in your email");
  if (isEmpty(phone)) throw new Error("Please fill in your phone");
  if (isEmpty(phone)) throw new Error("Please fill in your phone");
  if (isEmpty(userId)) throw new Error("Please fill in your userId");
  if (isEmpty(password)) throw new Error("Please fill in your password");

  // Validate UserId: at least 4 characters long and no unique characters
  if (userId.length < 4) {
    throw new Error("UserId must be at least 4 characters long");
  }
  const uniqueCharRegex = /[^a-zA-Z0-9]/;
  if (uniqueCharRegex.test(userId)) {
    throw new Error("UserId must not contain any special characters");
  }

  // Validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    throw new Error("Please enter a valid email address");
  }

  // Validate phone number: +1 followed by US area code and number
  const phoneRegex = /^\+1\d{10}$/;
  if (!phoneRegex.test(phone)) {
    throw new Error(
      "Phone number must be a valid US number starting with +1 followed by 10 digits"
    );
  }

  // Validate password: at least 8 characters long
  if (password.length < 8) {
    throw new Error("Password must be at least 8 characters long");
  }

  //appwrite will ensure that no duplicated account information exists
};

const isEmpty = (string) => {
  return !string || string.trim().length === 0;
};
